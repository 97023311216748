import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

function App() {
  const history = useHistory();
  const login = () => {
    window.netlifyIdentity.open();
  };
  useEffect(() => {
    const user = window.netlifyIdentity.currentUser();
    if (user) {
      return history.push("/appoinment");
    }
    window.netlifyIdentity.on("login", () => {
      window.netlifyIdentity.close();
      history.push("/appoinment");
    });
  }, [history]);

  return (
    <div className="container">
      <button className="button is-primary" onClick={login}>
        login
      </button>
    </div>
  );
}

export default App;
