import React, { useState, useEffect } from "react";
import axios from "axios";
import FuzzySearch from "fuzzy-search";

const PatientList = ({ onSelect }) => {
  const [data, setData] = useState();
  const [term, setTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    axios.get("/.netlify/functions/get-patients").then((r) => {
      setData(r.data);
      setIsLoading(false);
    });
  }, []);
  if (isLoading)
    return (
      <div>
        <span className="icon is-large">
          <i className="fas fa-spinner fa-pulse is-large"></i>
        </span>
      </div>
    );

  const searcher = new FuzzySearch(data, ["name", "lastname", "email"], {
    caseSensitive: false,
    sort: true,
  });

  const result = searcher.search(term);

  return (
    <div>
      <div>
        <input
          type="text"
          value={term}
          onChange={(e) => {
            setTerm(e.target.value);
          }}
        />
      </div>
      <div className="content" style={{ marginBottom: 10 }}>
        {result.length ? (
          <ul>
            {result
              .filter((_, i) => i < 5)
              .map((item) => (
                <li key={item._id} style={{ paddingBottom: 8 }}>
                  <button
                    className="button is-small is-info"
                    onClick={() => onSelect(item)}
                  >
                    Seleccionar
                  </button>
                  <div>
                    <strong>nombre apellidos:</strong> {item.name}{" "}
                    {item.lastname}
                  </div>
                  <div>
                    <strong>email:</strong> {item.email}
                  </div>
                </li>
              ))}
          </ul>
        ) : (
          <div>no hay resultados</div>
        )}
      </div>
    </div>
  );
};

export default PatientList;
