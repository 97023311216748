import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { format } from "fecha";

import PatientList from "./PatientList";

const Field = ({ label, children }) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">{children}</div>
    </div>
  );
};

const hours = [
  { id: "07:00", label: "7 am" },
  { id: "08:00", label: "8 am" },
  { id: "09:00", label: "9 am" },
  { id: "10:00", label: "10 am" },
  { id: "11:00", label: "11 am" },
  { id: "12:00", label: "12 pm" },
  { id: "13:00", label: "1 pm" },
  { id: "14:00", label: "2 pm" },
  { id: "15:00", label: "3 pm" },
  { id: "16:00", label: "4 pm" },
  { id: "17:00", label: "5 pm" },
  { id: "18:00", label: "6 pm" },
  { id: "19:00", label: "7 pm" },
  { id: "20:00", label: "8 pm" },
  { id: "21:00", label: "9 pm" },
  { id: "22:00", label: "10 pm" },
  { id: "23:00", label: "11 pm" },
];

function Appoinment() {
  const [loaded, setLoaded] = useState(false);
  const [newPatient, setNewPatient] = useState(false);
  const [patient, setPatient] = useState({});
  const history = useHistory();

  const {
    handleSubmit,
    reset,
    setValue,
    register,
    formState,
    watch,
  } = useForm();

  const onSubmit = ({ particular, prefix, ...values }) => {
    const empty =
      Object.values({ ...values, ...patient }).filter((f) => !f).length > 0;
    if (empty) return alert("Llena todos los campos");
    if (newPatient === true && particular === "") {
      return alert("Elige si es particular o no");
    }
    const d = new Date(values.date);
    const payload = {
      ...values,
      ...patient,
      date: `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`,
      newPatient: !!newPatient,
      particular,
      prefix,
    };
    return axios
      .post("/.netlify/functions/create-apoinment", {
        ...payload,
      })
      .then(() => {
        const hourLabel = hours.filter((item) => item.id === values.hour)[0]
          ?.label;
        alert(`Cita creada ${format(values.date, "D/MM/YYYY")} ${hourLabel}`);
        reset();
      });
  };

  useEffect(() => {
    register("date");
    register("particular");
  }, [register]);

  const handlePatientType = useCallback(
    (value) => {
      setPatient({});
      setValue("name", "");
      setValue("lastname", "");
      setValue("email", "");
      setNewPatient(value);
      setValue("particular", "");
      setValue("prefix", "");
    },
    [setValue]
  );

  const onSelect = (item) => {
    setPatient({
      name: item.name,
      lastname: item.lastname,
      email: item.email,
    });
    setValue("name", item.name);
    setValue("lastname", item.lastname);
    setValue("email", item.email);
    setValue("particular", "");
    setValue("prefix", "");
  };

  useEffect(() => {
    const user = window.netlifyIdentity.currentUser();
    if (!user) {
      return history.push("/");
    } else {
      setLoaded(true);
    }
  }, [history]);
  if (!loaded) {
    return (
      <div>
        <span className="icon is-large">
          <i className="fas fa-spinner fa-pulse is-large"></i>
        </span>
      </div>
    );
  }

  return (
    <div style={{ padding: 20 }}>
      <div className="field">
        <label className="label">Paciente nuevo?</label>
        <div className="control">
          <label className="radio">
            <input
              type="radio"
              name="yes"
              onClick={() => handlePatientType(true)}
              checked={newPatient === true}
            />
            SI
          </label>
          <label className="radio">
            <input
              type="radio"
              name="no"
              onClick={() => handlePatientType(false)}
              checked={newPatient === false}
            />
            No
          </label>
        </div>
      </div>
      {newPatient === false && <PatientList onSelect={onSelect} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field label="Nombre">
          <input
            className="input"
            name="name"
            ref={register}
            disabled={!newPatient}
          />
        </Field>
        <Field label="Apellido">
          <input
            className="input"
            name="lastname"
            ref={register}
            disabled={!newPatient}
          />
        </Field>
        <Field label="Email">
          <input
            className="input"
            type="email"
            name="email"
            ref={register}
            disabled={!newPatient}
          />
        </Field>
        <Field label="Fecha">
          <DatePicker
            className="input"
            selected={watch("date")}
            todayButton="hoy"
            dateFormat="dd-MM-yyyy"
            onChange={(date) => {
              setValue("date", date);
            }}
          />
        </Field>
        <Field label="Hora">
          <div className="select">
            <select ref={register} name="hour">
              <option value={""}>Seleccionar</option>
              {hours.map((hour) => (
                <option key={hour.id} value={hour.id}>
                  {hour.label}
                </option>
              ))}
            </select>
          </div>
        </Field>
        <Field label="Saludo (opcional)">
          <input className="input" name="prefix" ref={register} />
        </Field>
        {newPatient === true && (
          <div className="field">
            <label className="label">Paciente particular?</label>
            <div className="control">
              <label className="radio">
                <input
                  type="radio"
                  onClick={() => setValue("particular", true)}
                  checked={watch("particular") === true}
                />
                SI
              </label>
              <label className="radio">
                <input
                  type="radio"
                  onClick={() => setValue("particular", false)}
                  checked={watch("particular") === false}
                />
                No
              </label>
            </div>
          </div>
        )}
        <div className="field">
          <p className="control">
            <button
              className={`button is-success ${
                formState.isSubmitting ? "is-loading" : ""
              }`}
              disabled={formState.isSubmitting}
            >
              Crear cita
            </button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default Appoinment;
